body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--background-color);
    color: white;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
    --background-color: #1d2125;
    --primary-color: #e74e96;
    --secondary-color: #b34f7d;
    --highlight-color: #e976ab;
    --tertiary-color: #583345;
}

input {
    height: 3rem;
    border-radius: none;
    border: 1px solid black;
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
    font-size: 1.8rem;
    color: white;
    border: 3px solid var(--primary-color);
    background-color: var(--background-color);
    padding: 1rem;
}

input:focus {
    border-color: var(--secondary-color);
    outline: none;
}

button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 1rem;
    font-size: 2rem;
    outline: none;
    max-width: 50rem;
}

button:hover {
    cursor: pointer;
}

button:active {
    background-color: var(--highlight-color);
}

.selectable:hover {
    background-color: var(--primary-color);
    cursor: pointer;
    transition: background-color 0.2s;
}

.selectable:active {
    background-color: var(--highlight-color);
    cursor: pointer;
}

.selectable.disabled {
    background-color: var(--tertiary-color);
    color: var(--background-color);
}

.selectable.disabled:hover {
    background-color: var(--tertiary-color);
    color: var(--background-color);
    cursor: not-allowed;
}
