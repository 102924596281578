.page {
    display: flex;
    flex-direction: column;
}

.apps {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 80%;
    align-self: center;
    justify-content: center;
}

.app {
    margin: 1rem;
    width: 10rem;
    height: 10rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    font-size: 1.4rem;
    padding: 1.4rem;
    font-weight: bold;
}

.disconnect {
    margin: 0.7rem;
    font-size: 1rem;
}

.app-logo-wrapper {
    width: 7rem;
    height: 7rem;
    border-radius: 7rem;
    background-color: white;
    justify-content: center;
    display: flex;
    margin-bottom: 1rem;
}

.app-logo-image {
    max-width: 4.5rem;
    max-height: 4.5rem;
    align-self: center;
}

.top-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
}

.userInfo {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    position: absolute;
    right: 0;
    padding: 0.7rem;
}

.mainLogo {
    max-width: 300px;
    max-height: 200px;
    margin: 2rem;
    align-self: center;
    justify-self: center;
}
