.login-page {
    display: flex;
    flex-direction: column;
    margin: 2rem;
    text-align: center;
    justify-content: center;
    align-content: center;
}

h1 {
    font-size: 5rem;
}

input {
    align-self: center;
}

.login-page button {
    align-self: center;
}

.error-message {
    margin: 1rem;
    color: red;
    font-weight: bold;
}

.login-page a {
    color: white;
    background-color: var(--primary-color);
    text-decoration: none;
    font-weight: bold;
    padding: 1rem;
    align-self: center;
    max-width: 10rem;
}

.login-page a:hover {
    background-color: var(--secondary-color);
}

.login-page a:active {
    background-color: var(--highlight-color);
}
